export interface Host {
  url: string,
  stage: string
}

export default function getHost(): Host {
  const url = window.location.origin;

  if (url.includes('prod') || url.includes('purple-dune-01b981b03')) {
    return buildHost('prod');
  } else if (url.includes('staging') || url.includes('thankful-desert-08d392403')) {
    return buildHost('staging');
  } else if (url.includes('dev') || url.includes('black-tree-03ff2ae03')) {
    return buildHost('dev');
  } else if (url.includes('sandbox') || url.includes('jolly-sky-080959003')) {
    return buildHost('sandbox');
  } else if (url.includes('localhost') || window.location.origin.includes('local.example')) {
    return { url: 'http://localhost:3000', stage: 'local' };
  } else if (url.includes('cndmo')) {
    return buildHostCn('cndmo');
  } else if (url.includes('cnapp')) {
    return buildHostCn('cnapp');
  } else if (url.includes('cnprd')) {
    return buildHostCn('cnprd');
  }

  return buildHost('unknown-stage');
}

function buildHost(stage: string): Host {
  return {
    url: `https://portal.${ stage }.mod3-carapp-repository.cariad.digital`,
    stage: stage,
  };
}

function buildHostCn(stage: string): Host {
  return {
    url: `https://lscreen-portal-${ stage }.apps.mega.vwautocloud.cn`,
    stage: stage,
  };
}
