import { createBrowserHistory } from 'history';
import jwt_decode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';
import fullAccess from '../utils/full-access';
import { MessageContext } from './message-context';
import { TokenResponse, User } from '../interfaces/UserMeRespone';
import { RequestPermissions } from 'common/types/CloudidpJwtAuthToken';
import getRoles from '../components/roles';


export interface AuthContextType {
  loadToken: (authCode: string) => Promise<any>,
  getUser: () => User | undefined,
  getAllowedRoles: () => Map<string, RequestPermissions>,
  getHostUrl: () => string,
  allowedFullAccess: (oem: string) => boolean,
  isAllowToDownload: (oem: string) => boolean,
  allowedGetRequests: () => string[],
  isAuthenticated: () => boolean,
  logout: () => void,
  hasAllowedRoles: () => boolean,
}

const IDLE_LOGIN_MINUTES = 30;
const SECONDS_IN_MINUTE = 60;
const MILLIS_IN_SECOND = 1000;
const IDLE_LOGIN_MILLIS = IDLE_LOGIN_MINUTES * SECONDS_IN_MINUTE * MILLIS_IN_SECOND;
const IDLE_DEBOUNCE_MILLIS = 1000;

/* eslint-disable @typescript-eslint/no-unused-vars */
export const AuthContext = React.createContext<AuthContextType>({
  getUser: () => ({} as any),
  getAllowedRoles: () => ({} as Map<string, RequestPermissions>),
  getHostUrl: (): string => (''),
  allowedFullAccess: (_oem: string): boolean => false,
  isAllowToDownload: (_oem: string): boolean => false,
  allowedGetRequests: (): string[] => (['']),
  isAuthenticated: () : boolean => false,
  hasAllowedRoles: () :boolean => false,
} as AuthContextType);
/* eslint-enable @typescript-eslint/no-unused-vars */

export default function AuthProvider({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const browserHistory = createBrowserHistory();
  const messageContext = useContext(MessageContext);

  const [user, setUser] = useState<User>();
  const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();

  const logoutTimer = useIdleTimer({
    timeout: IDLE_LOGIN_MILLIS,
    onIdle: logout,
    startOnMount: false,
    debounce: IDLE_DEBOUNCE_MILLIS,
  });

  useEffect(() => {
    if (!isAuthenticated() &&
          location.pathname !== '/callback' &&
          location.pathname !== '/logoutsuccess'
    ) {
      loginRedirect();
    }
  }, []);

  useEffect(() => {
    if (user?.decodedToken) {
      const refreshMillis: number = (user.expiresInSeconds / 2) * MILLIS_IN_SECOND;
      setRefreshTimeout(setTimeout(refreshToken, refreshMillis));
    }
  }, [user?.decodedToken]);

  const isAuthenticated = (): boolean => !!user;

  const loginRedirect = () => {
    const authEndpoint = 'https://idp.cloud.vwgroup.com/auth/realms/kums/protocol/openid-connect/auth';
    const redirectUri = encodeURI(`${window.location.origin}/callback`);
    const clientId = 'idp-e25512a3-0f20-4a75-82d9-cd4cd45eb311-kums-apprepo-megatron-oauth';
    const url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20profile`;

    const targetPath = window.location.pathname;
    if (targetPath && !targetPath.includes('callback')) {
      localStorage.setItem('targetPath', targetPath);
    }
    browserHistory.push(url);
  };

  function loadToken(authCode: string) {
    return fetch(`${getHostUrl()}/api/get-auth-token?code=${authCode}`, {
      method: 'GET',
    })
      .then(handleTokenResponse)
      .catch(handleTokenError);
  }

  const handleTokenResponse = async (response: Response) => {
    if (!response.ok) {
      messageContext.addError('Auth Error:' + JSON.stringify(response.body, null, 2));
      return false;
    }

    const data: TokenResponse = await response.json();

    const newUser: User = {
      accessToken: data.access_token,
      decodedToken: jwt_decode(data.access_token),
      refreshToken: data.refresh_token,
      expiresInSeconds: data.expires_in,
    };
    setUser(newUser);

    if (getRoles(newUser.decodedToken!).size === 0) {
      browserHistory.replace('/about');
      navigate('/about');
    } else {
      const targetPath = localStorage.getItem('targetPath') || '/';
      localStorage.removeItem('targetPath');

      browserHistory.replace(targetPath);
      navigate(targetPath);
    }

    logoutTimer.reset();

    return true;
  };

  function handleTokenError(e: Error) {
    messageContext.addError(e.message);

    browserHistory.replace('/');
    navigate('/');
    return false;
  }

  function refreshToken() {
    setRefreshTimeout(undefined);

    if (user) {
      fetch(`${getHostUrl()}/api/refresh-auth-token?refresh_token=${user.refreshToken}`)
        .then(handleTokenResponse);
    }
  }

  function getUser(): User | undefined {
    return user;
  }

  function getAllowedRoles(): Map<string, RequestPermissions> {
    return user ? getRoles(user.decodedToken!) : new Map();
  }

  function allowedFullAccess(oem: string): boolean {
    let hasFullAccess: boolean = false;

    getAllowedRoles().forEach((value: RequestPermissions) => {
      if (value.oem === oem && fullAccess(value.requestPermissions)) hasFullAccess = true;
    });

    return hasFullAccess;
  }

  function hasAllowedRoles(): boolean {
    return getAllowedRoles().size > 0;
  }

  function allowedGetRequests(): string[] {
    const allowedOems: string[] = [];

    getAllowedRoles().forEach((value: RequestPermissions) => {
      if (!allowedOems.includes(value.oem.toLowerCase())) {
        allowedOems.push(value.oem);
      }
    });

    return allowedOems;
  }


  function isAllowToDownload(oem: string): boolean {
    let hasOem: boolean = false;

    getAllowedRoles().forEach((value: RequestPermissions) => {
      if (value.oem === oem) hasOem = true;
    });

    return hasOem;
  }

  function getHostUrl(): string {
    if (window.location.origin.includes('prod') || window.location.origin.includes('proud-pebble-028295e03')) {
      return 'https://car-prod-portal-backend.azurewebsites.net';
    } else if (window.location.origin.includes('staging') || window.location.origin.includes('thankful-desert-08d392403')) {
      return 'https://car-staging-portal-backend.azurewebsites.net';
    } else if (window.location.origin.includes('sandbox') || window.location.origin.includes('jolly-sky-080959003')) {
      return 'https://car-sandbox-portal-backend.azurewebsites.net';
    } else if (window.location.origin.includes('dev') || window.location.origin.includes('black-tree-03ff2ae03')) {
      return 'https://car-dev-portal-backend.azurewebsites.net';
    } else if (window.location.origin.includes('local')) {
      return 'http://localhost:7071';
      //return 'https://car-sandbox-portal-backend.azurewebsites.net';
    } else if (window.location.origin.includes('cndmo')) {
      return 'https://car-cndmo-portal-backend.car.vwg-connect.cn';
    } else if (window.location.origin.includes('cnapp')) {
      return 'https://car-cnapp-portal-backend.car.vwg-connect.cn';
    } else if (window.location.origin.includes('cnprd')) {
      return 'https://car-cnprd-portal-backend.car.vwg-connect.cn';
    }

    return 'https://car-sandbox-portal-backend.azurewebsites.net';
  }

  function logout() {
    navigate('/logoutsuccess');
    browserHistory.replace('/');

    setUser(undefined);
    clearTimeout(refreshTimeout);
    setRefreshTimeout(undefined);
  }


  return (
<AuthContext.Provider value={{
  loadToken,
  allowedFullAccess,
  allowedGetRequests,
  getAllowedRoles,
  getHostUrl,
  getUser,
  isAllowToDownload,
  isAuthenticated,
  hasAllowedRoles,
  logout,
}}>
  {children}
</AuthContext.Provider>
  );
}
