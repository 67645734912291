import { type CloudidpJwtAuthToken, type RequestPermissions } from 'common/types/CloudidpJwtAuthToken';
import SRA_ROLES_JSON from 'common/constants/roles.json';

const SRA_ROLES = getFERoles();

function getFERoles(): Map<string, RequestPermissions> {
  const frontEndRoles = new Map<string, RequestPermissions>();
  SRA_ROLES_JSON.SRA_ROLES.forEach((role: { key: string, value: string }) => {
    const split = role.value.split(',');
    frontEndRoles.set(role.key, { oem: split[0], requestPermissions: split[1].split(':') });
  });

  return frontEndRoles;
}

export default function getRoles(decodedToken: CloudidpJwtAuthToken): Map<string, RequestPermissions> {
  const roles = new Map<string, RequestPermissions>();

  if (decodedToken.groups) {
    for (const element of decodedToken.groups) {
      if (SRA_ROLES.has(element)) {
        roles.set(element, SRA_ROLES.get(element)!);
      }
    }
  } else if ((decodedToken.resource_access?.['vwag-kums'].roles) != null) {
    for (const element of decodedToken.resource_access?.['vwag-kums'].roles) {
      if (SRA_ROLES.has(element)) {
        roles.set(element, SRA_ROLES.get(element)!);
      }
    }
  }

  return roles;
}
