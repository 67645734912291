import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../../context/auth-context';
import { MessageContext } from '../../context/message-context';
import { CarApp } from '../../interfaces/CarApp';
import OverviewTable from './overview-table';
import { useContext, useEffect, useState } from 'react';
import { loadMetadata } from '../../utils/load-metadata';
import downloadCarApp from './download-car-app';
import deleteCarApp from './delete-car-app';
import { useNavigate } from 'react-router-dom';

export interface S3DownloadUrlResponse {
  's3CarAppDownloadUri': string,
  'message': string
}

export default function Overview() {
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [data, setData] = useState<CarApp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!authContext.hasAllowedRoles()) {
      navigate('/notallowed', { replace: true });
    } else {
      loadMetadata(setIsLoading, authContext, setData, messageContext);
    }
  }, []);

  function setupDeletion(app: CarApp) {
    deleteCarApp(app, setIsLoading, messageContext, authContext, setData);
  }

  function setupDownload(app: CarApp) {
    downloadCarApp(app, setIsLoading, messageContext, authContext);
  }

  return (
      <Grid container spacing={2} pt={3} justifyContent="center">
          {isLoading ? <CircularProgress/> :
              <OverviewTable
                  data={data}
                  setupDownload={setupDownload}
                  setupDeletion={setupDeletion}
              />
          }
      </Grid>
  );
}
