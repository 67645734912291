import { useEffect, useState } from 'react';
import { Select, SelectChangeEvent } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import { createBrowserHistory } from 'history';
import styled from '@emotion/styled';
import getHost from '../utils/GetHost';
import { blue, green, red, yellow } from '@mui/material/colors';

export default function StageIndikator() {

  const browserHistory = createBrowserHistory();
  const [selectedstage, setSelectedstage] = useState<string>('');
  const china = window.location.origin.includes('cndmo') || window.location.origin.includes('cnapp') || window.location.origin.includes('cnprd');
  const [stages, setStages] = useState<any[]>(china ? [
    { name: 'cndmo', color: green[400], nameToShow: 'Demo CN' },
    { name: 'cnapp', color: yellow[800], nameToShow: 'Approval CN' },
    { name: 'cnprd', color: red[800], nameToShow: 'Prod CN' },
  ] : [
    { name: 'dev', color: blue[900], nameToShow: 'dev' },
    { name: 'staging', color: yellow[400], nameToShow: 'staging' },
    { name: 'prod', color: red[400], nameToShow: 'prod' },
  ]);

  interface StageTagProps {
    color: string
  }

  const StageTag = styled.div<StageTagProps>`
  color: ${(props: StageTagProps) => props.color};
`;

  useEffect(() => {
    const stage = getHost().stage;
    setSelectedstage(stage);
    const newStages = stages;

    if (stage === 'local') {
      const isLocalStageExist = stages.some((stageName) => stageName.name === 'local');
      const isSandboxStageExist = stages.some((stageName) => stageName.name === 'sandbox');
      if (!isSandboxStageExist) {
        newStages.unshift({ name: 'sandbox', color: blue[400], nameToShow: 'sandbox' });
      }
      if (!isLocalStageExist) {
        newStages.unshift({ name: 'local', color: blue[400], nameToShow: 'local/sandbox' });
      }
    }

    if (stage === 'sandbox') {
      const isLocalStageExist = stages.some((stageName) => stageName.name === 'sandbox');
      if (!isLocalStageExist)
        newStages.unshift({ name: 'sandbox', color: blue[400], nameToShow: 'sandbox' });
    }

    setStages(newStages);

  }, []);

  function handleChangeStage(event: SelectChangeEvent) {
    const currentPath = window.location.pathname;
    const currentSearchParam = window.location.search;
    const targetHost = china ? `https://car-${ event.target.value }-portal.apps.mega.vwautocloud.cn` :
      `https://portal.${ event.target.value }.mod3-carapp-repository.cariad.digital`;

    browserHistory.push( targetHost + currentPath + currentSearchParam);
  }

  return (
    <Select
      data-testid="stage-selector"
      id="stage-selector"
      value={ selectedstage }
      onChange={ handleChangeStage }
      autoWidth
      sx={ {
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      } }

    >
      { stages.map((stage) => (
        <MenuItem value={ stage.name } key={ stage.name } sx = { { paddingLeft: '15px', margin: '0px', textAlign: 'center' } }>
          { stage.name === selectedstage ?
              <StageTag color={ stage.color } data-testid="stage-indicator" >
              { stage.nameToShow }
              </StageTag>
            :
              <StageTag color={ stage.color } data-testid={'stage-indicator-' + stage.name}>
                { stage.nameToShow }
              </StageTag>
          }
        </MenuItem>
      )) }
    </Select>
  );
}
