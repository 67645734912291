import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { createRef, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/auth-context';

import { MessageContext } from '../context/message-context';
import { useNavigate } from 'react-router-dom';
/*
interface ResponseJSON {
  statusCode: number
  headers: {}
  multiValueHeaders: {}
  body: string
}

interface PostMetadataResponse {
  message: string
}
*/
export const UPLOAD_ENDPOINT = '/api/upload-app';


export default function Upload() {
  const messageContext = useContext(MessageContext);
  const [file, setFile] = useState<File>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const inputRef = createRef<HTMLInputElement>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext.hasAllowedRoles()) {
      navigate('/notallowed', { replace: true });
    }

  }, []);

  async function uploadCarApp() {
    if (!file) {
      return Promise.reject('No file selected!');
    }

    const formData = new FormData();
    formData.append('file', file);

    return fetch(authContext.getHostUrl() + UPLOAD_ENDPOINT, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authContext.getUser()?.accessToken}`,
      },
      body: formData,
    })
      .then(async (res: Response) => {
        if (res.ok) {
          messageContext.addSuccess((await res.json()).message);
        } else {
          messageContext.addError('Upload Error: ' + (await res.text()));
        }
      }).catch((e: Error) => {
        // other errors, possibly azure function request size limit
        messageContext.addError('Upload Error: ' + e.message + '\n Max Request Size: 100MB');
      });
  }

  async function onSubmit() {
    setSubmitted(true);

    if (inputRef.current != null) {
      inputRef.current.value = '';
    }

    await uploadCarApp().catch((uploadError: Error) => {
      console.log(uploadError.message);
    }).finally(() => {
      setSubmitted(false);
    });

    setFile(undefined);
  }

  function selectFile(inputFile: File | null): void {
    if (inputFile != null) {
      setFile(inputFile);
    }
  }
  return (
    <div>
      <div>
        <Box display="flex" flexDirection="column" alignItems="center">
          {!submitted && (
            <Box
              maxWidth='600px'
              display='flex'
              flexDirection='column'
              alignItems='center'
              padding={2}
              borderRadius="5px"
              border="solid 1px grey"
            >
              <input
                ref={inputRef}
                type="file"
                accept=".zip"
                onChange={(e) => {
                  selectFile(e.target.files!.item(0));
                }}
                data-testid="select-file-button"
              />
              <p style={{ marginTop: 20 }}>This upload is designed for carapp zip files. You can use carapps from the
                old CMS AEM Dashboard as well as the carapps you can download here.
              </p>
              {file && (
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  fullWidth
                  sx={{ marginTop: 2, fontSize: '17px' }}
                  data-testid="upload-button"
                >
                  UPLOAD
                </Button>
              )}
            </Box>
          )}
          {submitted && <CircularProgress/>}
        </Box>
      </div>
    </div>
  );
}
